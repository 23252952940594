/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

.auth-container {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  text-align: center;
}

button,
button:active,
button:hover {
  color: $white !important;
}

.actions-container,
.table-search-container,
.table-search-header {
  background-color: $gray-100;
}

.actions-container {
  border-radius: 3px;
  padding: 10px;
}

.table-search-container {
  border-bottom: 1px solid var(--bs-border-color);
  padding: 10px;
}

.table {
  vertical-align: middle;
}

.btn-action {
  border-radius: 50%;
}

.simple-table .page-link:focus,
.simple-table .form-select:focus {
  box-shadow: none;
}

.simple-table .form-select {
  color: #0073dd;
  height: fit-content;
  width: fit-content;
}
